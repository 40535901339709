<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    height: {
      default: 180
    },
    labels: {type: Array},
    data: {type: Array},
    isLoading: {
      type: Boolean,
      default: false
    },
    chartData: {required: false}
  },
  data () {
    return {
      options: {
        responsive: true,
        aspectRatio: 5,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              min: 0,
              display: false
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              min: 0,
              display: false
            }
          }],
        }
      }
    }
  },
  watch: {
    getData () {
      this.render()
    }
  },
  computed: {
    getData () {
      if (this.isLoading) {
        return [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
      } else {
        return this.data
      }
    },
    getLabels () {
      if (this.isLoading) {
        return [100,10,25,1,25,10,1,10,25,12,25,10,5,10,25,12,25,10,5,10,25,12,25,10,0]
      } else {
        return this.labels
      }
    },
    getChartData () {
      return {
        labels: this.getLabels,
        datasets: [
          {
            barPercentage: 1.1,
            backgroundColor: '#cccccc',
            hoverBackgroundColor: '#018dca',
            data: this.getData
          }
        ]
      }
    }    
  },
  methods: {
    render () {
      this.renderChart(this.getChartData, this.options)
    }
  },
  mounted () {
    this.render()
  }
};
</script>

<style>
</style>
