<template>
  <div class="donutChart" :class="donutVariantClass">
    <transition name="fade" mode="out-in">
      <div v-if="isLoading" class="loading" key="value">
        <div class="number-info" :class="numberClass">
          <div class="number-info--date">
            <small>...</small>
          </div>
          <div class="number-info--description">
            <p>Loading</p>
          </div>
        </div>
        <SpinnerPulse width="100%" height="100%" />
      </div>
      <div v-else key="percent">
        <StatNumber :value="value" :extraClasses="numberClass" :description="description" :type="type" />
        <svg width="100%" height="100%" viewBox="0 0 42 42">
          <circle cx="21" cy="21" r="16" :stroke-dasharray="getStrokeDasharray"
              stroke-dashoffset="25">
          </circle>
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>

import StatNumber from '@/components/StatNumber.vue'
import SpinnerPulse from '@/components/SpinnerPulse.vue'

export default {
  components: {
    StatNumber,
    SpinnerPulse
  },
  props: {
    variant: {
      type: String,
      default: 'type1'
    },
    value: {
      default: 0
    },
    type: {
      type: String,
      default: 'number',
      validator: function (value) {
        return ['percentage', 'number', 'time'].indexOf(value) !== -1
      }
    },
    percent: {
      default: null
    },
    description: {
      type: String,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getPercent () {
      let parsed = this.percent
      if (this.percent !== null && this.percent !== 0) {
        if (this.type === 'percent') {
          parsed = parsed.slice(0, -1)
        }
        return parseInt(parsed)
      }
      return 100
    },
    getStrokeDasharray () {
      let value = this.getPercent
      let complementary = 100 - value
      return (value + " " + complementary)
    },
    donutVariantClass () {
      return "donutChart-" + this.variant
    },
    numberClass () {
      if (this.variant === 'type2') {
        return 'number-info number-info--med'
      }
      return "number-info"
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
};
</script>

<style lang="scss">
.donutChart {
  transition: all .2s ease;
  border-radius: 300px;
  .number-info--date {
    transition: transform .2s ease;
  }
}
.donutChart:hover {
  background: linear-gradient(0deg, rgba(100,100,100,0.1) 0%, rgba(255,255,255,0) 31%);
  &::after {
    border-color: #e6e6e6 !important;
    border-width: 6px;
  }
  .number-info--date {
    transform: scale(1.1);
  }
}
.donutChart.donutChart-type2 svg {
  transform: scale(1.18);
}
.donutChart.donutChart-type1 svg, .donutChart.donutChart-type3 svg {
  transform: scale(1.2);
}
.donutChart.donutChart-type1 .loading svg, .donutChart.donutChart-type3 .loading svg {
  stroke-width: 0.1;
  transform: scale(1);
}
.fade-enter-active, .fade-leave-active {
  transition: all .1s ease;
}
.fade-enter {
  opacity: 0;
  transform: scale(0);
}
.fade-leave-to {
  opacity: 0;
  transform: scale(0);
}

</style>