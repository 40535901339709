<template>
  <div class="stats-table"> 
    <ul class="list-group list-group-flush list-group--mobilemarginfix" v-if="!isLoading">
      <li class="list-group-item list-group-item--head">
        <div class="list-group-item--left">
          <h5 class="mod-header--title c-primary">Medias</h5>
        </div>
        <div class="list-group-item--right">
          <div class="list-group-item--cell">
            <span>%</span>
          </div>
          <div class="list-group-item--cell">
            <span>Views</span>
          </div>
          <div class="list-group-item--cell">
            <span>Time</span>
          </div>
        </div>
      </li>
      <li class="list-group-item" :key="item.code" v-for="item in top">
        <div class="list-group-item--left">
          <em>{{item.name}}</em>
        </div>
        <div class="list-group-item--right">
          <div class="list-group-item--cell">
            <strong>{{item.percentage}}</strong>
          </div>
          <div class="list-group-item--cell">
            <strong>{{item.visits}}</strong>
          </div>
          <div class="list-group-item--cell">
            <StatNumber :value="item.time" type="time" extraClasses="number-info--flat" />
          </div>
        </div>
      </li>
    </ul>
    <ul class="list-group list-group-flush list-group--mobilemarginfix" v-else>
      <li class="list-group-item list-group-item--head">
        <div class="list-group-item--left">
          <h5 class="mod-header--title c-primary">Medias</h5>
        </div>
        <div class="list-group-item--right">
          <div class="list-group-item--cell">
            <span>%</span>
          </div>
          <div class="list-group-item--cell">
            <span>Views</span>
          </div>
          <div class="list-group-item--cell">
            <span>Time</span>
          </div>
        </div>
      </li>
      <li class="list-group-item" :key="item.name" v-for="item in loadingData">
        <div class="list-group-item--left">
          <skeleton-box />
        </div>
        <div class="list-group-item--right">
          <div class="list-group-item--cell">
            <skeleton-box :max-width="20" />
          </div>
          <div class="list-group-item--cell">
            <skeleton-box :max-width="20" />
          </div>
          <div class="list-group-item--cell">
            <skeleton-box :max-width="20" />
          </div>
        </div>
      </li>
    </ul>

    <div class="overlay d-flex h-100" v-if="!isLoading" @click="showModal = !showModal">
      <div class="justify-content-center align-self-center m-auto">
        <span class="material-icons">
        zoom_in
        </span>
      </div> 
    </div>
    <portal to="modals" v-if="showModal">
      <b-modal v-model="showModal" title="Medias" ok-only>
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th style="text-align: left">Media</th>
              <th style="text-align: center">Percentage</th>
              <th style="text-align: right">Views</th>
              <th style="text-align: right">Time</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="item.code" v-for="item in data">
              <td align="left" class="overflow-ellipsis" :title="item.name">{{item.name}}</td>
              <td align="center">{{item.percentage}}</td>
              <td align="right">{{item.visits}}</td>
              <td align="right">
                <StatNumber :value="item.time" type="time" />
              </td>
            </tr>
          </tbody>
        </table>
      </b-modal>
    </portal>
  </div>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import SkeletonBox from '@/components/SkeletonBox.vue'
import StatNumber from '@/components/StatNumber.vue'

export default {
  components: {
    StatNumber,
    SkeletonBox,
    BModal
  },
  props: {
    data: {type: Array},
    show: {type: Number, default: 5},
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    top () {
      return this.data.slice(0, this.show)
    }
  },
  data () {
    return {
      showModal: false,
      loadingData: [
          {"name": ".", "percentage": '...', "views": '...', "time": '...'},
          {"name": "..", "percentage": '...', "views": '...', "time": '...'},
          {"name": "...", "percentage": '...', "views": '...', "time": '...'},
          {"name": "....", "percentage": '...', "views": '...', "time": '...'},
          {"name": ".....", "percentage": '...', "views": '...', "time": '...'}
        ]
    }
  },
  mounted () {
  }
};
</script>

<style lang="scss">
body .wrapper .stats-table .list-group .list-group-item.list-group-item--head {
  border-bottom: none;
  padding-bottom: .5em;
}

.stats-table {
  position: relative;
  cursor: pointer;

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(1,141,202, 0.05);
    opacity: 0;
    transition: all 0.5s ease;
    transform: scale(0);
    span {
      font-size: 2em;
      color: rgba(1,141,202, .9);
    }
  }
  &:hover {
    .overlay {
      opacity: 1;
      transform: scale(1);
    }
  }

  .overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}
</style>
