<template>
  <div class="mod mod-resume">
      <div class="mod-body">
          <!--list-group-->
          <ul class="list-group list-group-flush list-group--full list-group--display">
              <li class="list-group-item list-group-item--head no-border">
                  <div class="list-group-item--left">
                  </div>
                  <div class="list-group-item--right">
                      <div class="list-group-item--cell">
                          <span>Percentage</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Total Visits</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Total Users</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Time average</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Country</span>
                      </div>
                      <div class="list-group-item--cell" @click="showModalChart">
                          <span>Evolution <i class="material-icons">zoom_in</i></span>
                      </div>
                  </div>
              </li>
              <li class="list-group-item no-border">
                  <div class="list-group-item--left">
                      <div class="item-thumb--selector">
                          <router-link tag="button" :to="{name: 'tourDetail', params: {code: prevTour.code}}" class="btn item-thumb--selector---link item-thumb--selector---up">
                              <span class="material-icons">
                                  expand_less
                              </span>
                          </router-link>
                          <div class="item-thumb">
                              <img :src="tour.thumb">
                              <span class="item-thumb--title item-thumb--title---alltext">
                                  {{tour.name}}
                                  <!-- small>15/02/2019</small -->
                                  <button class="btn btn-light btn-sm btn-share" @click.prevent="showShare=true">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.12 13" style="enable-background:new 0 0 12.12 13;" xml:space="preserve">
                                      <g>
                                        <path class="st0" d="M9.92,8.59c-0.59,0-1.14,0.23-1.56,0.65c-0.12,0.12-0.21,0.24-0.3,0.38L4.25,7.3c0.1-0.25,0.16-0.53,0.16-0.81
                                          S4.35,5.93,4.25,5.68l3.81-2.29C8.45,4,9.13,4.41,9.92,4.41c1.21,0,2.2-0.99,2.2-2.2S11.13,0,9.92,0s-2.2,0.99-2.2,2.2
                                          c0,0.28,0.05,0.54,0.15,0.78l-3.81,2.3c-0.39-0.6-1.07-1-1.84-1C0.99,4.29,0,5.28,0,6.49s0.99,2.2,2.2,2.2c0.77,0,1.45-0.4,1.84-1
                                          l3.81,2.31c-0.09,0.25-0.15,0.51-0.15,0.79c0,0.59,0.23,1.14,0.65,1.56C8.77,12.77,9.33,13,9.92,13s1.14-0.23,1.56-0.65
                                          c0.42-0.42,0.65-0.97,0.65-1.56s-0.23-1.14-0.65-1.56C11.06,8.82,10.5,8.59,9.92,8.59z"/>
                                      </g>
                                    </svg> Share Report
                                  </button>
                              </span>
                          </div>
                          <router-link tag="button" :to="{name: 'tourDetail', params: {code: nextTour.code}}" class="btn item-thumb--selector---link item-thumb--selector---down">
                              <span class="material-icons">
                                  expand_more
                              </span>
                          </router-link>
                      </div>
                  </div>
                  <div class="list-group-item--right">
                      <div class="list-group-item--cell list-group-item--active">
                          <!--donutChart-item-->
                          <DoughnutChart :value="tour.percentage" :percent="tour.percentage" type="percentage" variant="type2" />
                          <!--/donutChart-item-->
                      </div>
                      <div class="list-group-item--cell">
                        <div class="statnumber--wrapper">
                          <StatNumber :value="tour.visits" type="number"></StatNumber>
                        </div>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.users" type="number"></StatNumber>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.time" type="time"></StatNumber>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.top_country.percentage" type="percentage" :description="tour.top_country.country"></StatNumber>
                      </div>
                      <div class="list-group-item--cell" @click="showModal = true">
                        <ChartVisits :labels="tour.evolution_chart.labels" :height="80" :width="110" :data="tour.evolution_chart.values"></ChartVisits>
                      </div>
                  </div>
              </li>
              <li class="list-group-item list-group-item--head no-border list-group-item---mobile">
                  <div class="list-group-item--center">
                      <div class="list-group-item--cell">
                          <span>Total Visits</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Total Users</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Time average</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Country</span>
                      </div>
                  </div>
              </li>
              <li class="list-group-item no-border list-group-item---mobile">
                  <div class="list-group-item--center">
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.visits" type="number"></StatNumber>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.users" type="number"></StatNumber>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.time" type="time"></StatNumber>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.top_country.percentage" type="percentage" :description="tour.top_country.country"></StatNumber>
                      </div>
                  </div>
              </li>
              <li class="list-group-item list-group-item---mobile" @click="showModalChart">
                  <div class="list-group-item--center">
                      <div class="list-group-item--cell">
                          <ChartVisits :labels="tour.evolution_chart.labels" :height="80" :width="110" :data="tour.evolution_chart.values"></ChartVisits>
                      </div>
                  </div>
              </li>
          </ul>
          <!--/list-group-->
      </div>
    <portal to="modals" v-if="showModal">
      <b-modal v-model="showModal" title="Daily stats" v-if="showModal" @show="isLoading=true" @shown="isLoading=false" ok-only>
        <ChartVisits :labels="detailChart.labels" :isLoading="isLoading" :height="250" :width="400" :data="detailChart.values"></ChartVisits>
      </b-modal>
    </portal>

    <portal to="modals" v-if="showShare">
      <b-modal v-model="showShare" title="Share report" hide-footer>
        <div class="mb-3">
          <label for="shareLinkInput" class="form-label">Report link</label>
          <div class="input-group mb-3">
            <input id="shareLinkInput" type="text" onClick="this.select();" class="form-control" :value="tourSharedLink" readonly>
            <button class="btn btn-outline-secondary" type="button" id="button-copy-link" @click.prevent="copyLinkButton">Copy</button>
          </div>
          <div class="text-muted">Share this link with anyone you want</div>
        </div>
        <div class="mb-3">
          <button class="btn btn-primary" :disabled="isRegenerating" type="button" @click.prevent="handleRegenerateLink"><i class="material-icons mr-2">sync</i> Reset link</button>
        </div>
      </b-modal>
    </portal>

  </div>

</template>

<script>
  import DoughnutChart from '@/components/DoughnutChart.vue'
  import ChartVisits from '@/components/ChartVisits.vue'
  import StatNumber from '@/components/StatNumber.vue'
  import { BModal } from 'bootstrap-vue'
  import { mapActions } from 'vuex'


  const copyToClipboard = str => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(str)
    }
    return Promise.reject('The Clipboard API is not available.')
  }


  export default {
    components: {
      DoughnutChart,
      ChartVisits,
      StatNumber,
      BModal
    },
    data () {
      return {
        showModal: false,
        showShare: false,
        isLoading: true,
        isRegenerating: false
      }
    },
    computed: {
      isTourDetail() {
        return this.$route.name === "tourDetail" 
      },
      isSharedTourDetail() {
        return this.$route.name === "sharedTourDetail" 
      },
      tourSharedLink() {
        if (this.$route.name === "tourDetail" && this.tour) {
          return document.location.protocol + '//' + document.location.host + '/#/sharedtour/' + this.tour.uuid
        } else {
          return ""
        }
      }
    },
    methods: {
      ...mapActions(['regenerateTourUuid']),
      copyLinkButton () {
        copyToClipboard(this.tourSharedLink).then(() => {
          this.$swal.fire(
            'Link copied',
            'Now you can paste it wherever you want. Use [CTRL+V] or [COMMAND + V].',
            'success'
          )
          this.showShare = false
        }).catch(() => {
          this.$swal.fire(
            'Ooops',
            'Your browser is not compatible with clipboard, so you should select text and press [CTRL+C] or [COMMAND + C] to copy the link.',
            'warning'
          )          
        })
      },
      showModalChart () {
        this.showModal = true
        this.isLoading = true
      },
      handleRegenerateLink () {

        this.$swal.fire({
              title: 'Reset URL',
              text: 'By doing this, a new URL to share your analytics will be created. The previous URL will not work anymore.',
              icon: null,
              cancelButtonText: "No",
              confirmButtonText: "Yes",
              showCancelButton: true,
              reverseButtons: true
            }
          ).then((result) => {
            if (result.isConfirmed) {
              this.isRegenerating = true
              this.regenerateTourUuid(this.tour).then(() => {
                // alert(data.uuid)
                this.isRegenerating = false
                this.copyLinkButton()
              })
            }
          })
      }
    },
    props: {
      tour: {
        type: Object
      },
      prevTour: {
        type: Object
      },
      nextTour: {
        type: Object
      },
      detailChart: {
        type: Object
      }
    }
  }; 
</script>

<style lang="scss">
  .list-group-item--cell span i {
    font-size: 1em;
  }
  body .wrapper .mod .list-group.list-group--full li.list-group-item:hover {
    background-color: transparent;
  }
  .mod.mod-resume {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .btn-share {
    padding: 0.1rem 0.5rem;
    border-radius: 25px;
    border: 1px solid #999;
    color: #999;
    svg {
      height: 14px;
      margin-right: 6px;
      .st0 {fill: #999}
    }
    &:hover {
      border-color: #008dc9;
      background-color: #008dc9;
      color: #fff;
      svg {
        .st0 {
          fill: #fff;
        }
      }
    }
  }
  .modal-header .close {
    font-size: 1.8rem;
  }
  .swal2-popup {
    padding: 3em 0em;
  }
  .swal2-styled {
    min-width: 7em;
  }
</style>
