<template>
  <div class="mod-body mod-body--space">
    <div class="row" v-if="media">
      <div class="col-md-auto text-center col-special-fix">
        <!--donutChart-item-->
        <DoughnutChart :value="media.percentage" :percent="media.percentage" type="percentage" variant="type3" description="Views" />
        <!--/donutChart-item-->
      </div>
      <div class="col-md" v-if="media">
        <div class="row row-media-details">
          <div class="col-lg col-md-6 col-sm col">
            <StatNumber :value="media.visits" upsideDown description="Views" type="number" />
          </div>
          <div class="col-lg col-md-6 col-sm col">
            <StatNumber :value="media.time" upsideDown description="Time average" type="time" />
          </div>
          <div class="col-lg col-md-6 col-sm col">
            <StatNumber :value="media.hotspots_sum" upsideDown description="Hotspots" type="number" />
          </div>
          <div class="col-lg col-md-6 col-sm col">
            <StatNumber :value="media.hotspots_clicks" upsideDown description="HS Clicks" type="number" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <!--list-group-->
            <MediaHotspotStatsTable :data="media.hotspots"></MediaHotspotStatsTable>
            <!--/<list-group-->
          </div>
        </div>
      </div>
      <div class="col" v-if="!media">
        <div class="alert alert-info">
          Choose a media
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from '@/components/DoughnutChart.vue'
import StatNumber from '@/components/StatNumber.vue'
import MediaHotspotStatsTable from '@/components/MediaHotspotStatsTable.vue'

export default {
  components: {
    DoughnutChart,
    MediaHotspotStatsTable,
    StatNumber
  },
  props: {
    media: {type: Object}
  }
};

</script>
