<template>
  <ul class="list-group list-group-flush list-group--full list-group--display tour-list">
      <li class="list-group-item list-group-item--head d-none d-lg-flex">
          <div class="list-group-item--left">
          </div>
          <div class="list-group-item--right">
              <div @click.prevent="toggleColumn('percentage')" class="list-group-item--cell" :class="{'list-group-item--active': selectedColumn.key === 'percentage'}">
                  <span>Percentage <small v-if="selectedColumn.key === 'percentage' && sortOrder==='asc'">&uarr;</small> <small v-if="selectedColumn.key === 'percentage' && sortOrder==='desc'">&darr;</small></span>
              </div>
              <div @click.prevent="toggleColumn('visits')" class="list-group-item--cell" :class="{'list-group-item--active': selectedColumn.key === 'visits'}">
                  <span>Total Visits <small v-if="selectedColumn.key === 'visits' && sortOrder==='asc'">&uarr;</small> <small v-if="selectedColumn.key === 'visits' && sortOrder==='desc'">&darr;</small></span>
              </div>
              <div @click.prevent="toggleColumn('users')" class="list-group-item--cell" :class="{'list-group-item--active': selectedColumn.key === 'users'}">
                  <span>Total Users <small v-if="selectedColumn.key === 'users' && sortOrder==='asc'">&uarr;</small> <small v-if="selectedColumn.key === 'users' && sortOrder==='desc'">&darr;</small></span>
              </div>
              <div @click.prevent="toggleColumn('time')" class="list-group-item--cell" :class="{'list-group-item--active': selectedColumn.key === 'time'}">
                  <span>Time average <small v-if="selectedColumn.key === 'time' && sortOrder==='asc'">&uarr;</small> <small v-if="selectedColumn.key === 'time' && sortOrder==='desc'">&darr;</small></span>
              </div>
              <div @click.prevent="toggleColumn('country')" class="list-group-item--cell" :class="{'list-group-item--active': selectedColumn.key === 'country'}">
                  <span>Country <small v-if="selectedColumn.key === 'country' && sortOrder==='asc'">&uarr;</small> <small v-if="selectedColumn.key === 'country' && sortOrder==='desc'">&darr;</small></span>
              </div>
              <div class="list-group-item--cell">
                  <span>Evolution</span>
              </div>
          </div>
      </li>
      <li class="list-group-item list-group-item--head d-flex d-lg-none">
          <div class="list-group-item--left">
          </div>
          <div class="list-group-item--right">
              <div class="list-group-item--cell list-group-item--active" v-touch:swipe="swipeHandler">
                <span class="btn-group">
                  <b-dropdown class="mx-1" variant="light" right :text="selectedColumn.name">
                    <b-dropdown-item @click="selectColumn('percentage')">Percentage</b-dropdown-item>
                    <b-dropdown-item @click="selectColumn('visits')">Total Visits</b-dropdown-item>
                    <b-dropdown-item @click="selectColumn('users')">Total Users</b-dropdown-item>
                    <b-dropdown-item @click="selectColumn('time')">Time average</b-dropdown-item>
                    <b-dropdown-item @click="selectColumn('country')">Country</b-dropdown-item>
                  </b-dropdown>
                  <button class="btn btn-light" :class="{active: sortOrder==='asc'}" @click.prevent="doSort('asc')">&uarr;</button>
                  <button class="btn btn-light" :class="{active: sortOrder==='desc'}" @click.prevent="doSort('desc')">&darr;</button>
                </span>
              </div>
          </div>
      </li>
      <TourListItem v-for="tour in getTours" :mobileColumn="selectedColumn.name" :key="tour.code" :tour="tour" v-touch:swipe="swipeHandler"></TourListItem>
      <div class="overlay" v-show="isLoading">
        <SpinnerPulse height="80px"></SpinnerPulse>
      </div>
  </ul>
</template>

<script>
  import TourListItem from '@/components/TourListItem.vue'
  import SpinnerPulse from '@/components/SpinnerPulse.vue'
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import { BDropdown, BDropdownItem } from 'bootstrap-vue'

  export default {
    components: {
      TourListItem,
      SpinnerPulse,
      BDropdown,
      BDropdownItem
    },
    computed: {
      ...mapGetters(['getTours', 'getDateFilterPayload', 'isLoggedIn', 'getSortConfig'])
    },
    watch: {
      isLoggedIn () {
        this.dataSync()
      },
      getDateFilterPayload () {
        this.dataSync()
      }
    },
    methods: {
      ...mapActions(['updateTours']),
      ...mapMutations(['sortTours', 'setSortConfig']),
      dataSync () {
        this.isLoading = true
        this.updateTours(this.getDateFilterPayload).finally(() => {
          this.doSort(this.sortOrder)
          this.isLoading = false
        })
      },
      doSort (order) {
        this.sortOrder = order
        let sortConfig = { sortOrder: order, sortKey: this.selectedColumn.key }
        this.setSortConfig(sortConfig)
        this.sortTours(sortConfig)
      },
      selectColumn (key) {
        this.selectedColumn = this.columnDef[key]
        this.doSort(this.sortOrder)
      },
      swipeHandler (direction) {
        let currentPosition = this.columns.indexOf(this.selectedColumn.key)
        let numColumns = this.columns.length
        let newPosition = currentPosition

        if (direction === "left") {
          newPosition -= 1
          if (newPosition < 0) {
            newPosition = numColumns - 1
          }
        }
        if (direction === "right") {
          newPosition += 1
          if (newPosition >= numColumns) {
            newPosition = 0
          }
        }
        if (newPosition !== currentPosition) {
          this.selectColumn(this.columns[newPosition])
        }
      },
      toggleColumn (key) {
        this.selectedColumn = this.columnDef[key]
        if (this.sortOrder === 'asc') {
          this.sortOrder = 'desc'
        } else {
          this.sortOrder = 'asc'
        }
        this.doSort(this.sortOrder)
      }
    },
    created () {
      this.sortOrder = this.getSortConfig.sortOrder
      this.selectedColumn = this.columnDef[this.getSortConfig.sortKey]
      this.dataSync()
    },
    data () {
      return {
        isLoading: false,
        columns: [
          "percentage",
          "visits",
          "users",
          "time",
          "country"
        ],
        columnDef: {
          "percentage": {
            name: "Percentage",
            key: "percentage"
          },
          "visits": {
            name: "Total Visits",
            key: "visits"
          },
          "users": {
            name: "Total Users",
            key: "users"
          },
          "time": {
            name: "Time average",
            key: "time"
          },
          "country": {
            name: "Country",
            key: "country"
          },
        },
        sortOrder: 'desc',
        selectedColumn: {
          name: "Percentage",
          key: "percentage"
        }
      }
    }
  };
</script>

<style lang="scss">

.tour-list {
  margin-top: -70px;
  @media screen and (max-width: 767px) {
    margin-top: 0px;
  }
}

</style>