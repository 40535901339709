<template>
  <div>
    <!--mod // GLOBAL ANALYTICS ////////////////////-->
    <CollapsibleArea title="Global Analytics">
      <GlobalAnalytics></GlobalAnalytics>
    </CollapsibleArea>
    <!--/mod // GLOBAL ANALYTICS ////////////////////-->
    <!--mod // ANALYTICS by ////////////////////-->
    <CollapsibleArea extraClasses="mod-gradient" title="Analytics by Tour">
      <!--list-group-->
      <TourList></TourList>
      <!--/<list-group-->
    </CollapsibleArea>
    <!--/mod // ANALYTICS by ////////////////////-->
  </div>
</template>

<script>
  import CollapsibleArea from '@/components/CollapsibleArea.vue'
  import GlobalAnalytics from '@/components/GlobalAnalytics.vue'
  import TourList from '@/components/TourList.vue'

  export default {
    components: {
      GlobalAnalytics,
      CollapsibleArea,
      TourList
    }
  };
</script>