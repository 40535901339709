<template>
  <div class="col-md col-extra" style="margin-top: -58px">
    <!--mod-header-->
    <div class="mod-header">
      <h5 class="mod-header--title c-primary">{{modTitle}}</h5>
      <div class="mod-header--tools">
        <em class="mod-header--info d-block mt-4">Only panoramas and 360&deg; videos feature heatmaps.</em>
      </div>
    </div>
    <!--/mod-header-->
    <!--mod-body-->
    <div class="mod-body mod-body--space">
      <div class="mod-gallery" ref="gallery">
        <!--Gallery-->
        <div class="carousel slide">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="mod-gallery--box" ref="galleryBox" @dblclick.prevent="toggleFullscreen">
                <figure class="mod-gallery--box---heatmap text-center" v-if="showHeatmap">
                  <img :src="heatmapURL" ref="heatmap" alt="Heatmap" :style="{opacity: heatmapOpacity}" v-if="!loadingHeatmap && !heatmapError" width="100%">
                  <span class="material-icons text-danger errorIcon" v-if="!loadingHeatmap && heatmapError">error_outline</span>
                  <SpinnerPulse v-if="loadingHeatmap"></SpinnerPulse>
                </figure>
                <figure class="mod-gallery--box---image">
                  <img :src="media.panorama" ref="thumb" alt="Media" v-if="media.media_type==='panorama'">
                  <img :src="mediaThumb" ref="thumb" alt="Media" v-else>
                </figure>
                <div class="mod-gallery--box---legend">
                  <h6>{{media.name}}</h6>
                  <span class="btn-group btn-opacity">
                    <a href="javascript:;" class="btn btn-primary btn-sm" v-if="media.media_type==='panorama'" :class="{active: heatmapOpacity === 0}" @click.prevent="setHeatmapOpacity(0)">OFF</a>
                    <a href="javascript:;" class="btn btn-primary btn-sm" :class="{active: heatmapOpacity === .25}" v-if="media.media_type==='panorama'" @click.prevent="setHeatmapOpacity(.25)">25%</a>
                    <a href="javascript:;" class="btn btn-primary btn-sm" v-if="media.media_type==='panorama'" :class="{active: heatmapOpacity === .5}" @click.prevent="setHeatmapOpacity(.5)">50%</a>
                    <a href="javascript:;" class="btn btn-primary btn-sm" v-if="media.media_type==='panorama'" :class="{active: heatmapOpacity === .75}" @click.prevent="setHeatmapOpacity(.75)">75%</a>
                    <a href="javascript:;" class="btn btn-primary btn-sm" v-if="media.media_type==='panorama'" :class="{active: heatmapOpacity === 1}" @click.prevent="setHeatmapOpacity(1)">100%</a>
                  </span>
                  <button class="btn btn-outline-light" @click.prevent="toggleFullscreen"><span class="material-icons">{{icon}}</span></button>
                </div>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" @click.prevent="prevMedia">
            <span class="material-icons">
              chevron_left
            </span>
          </a>
          <a class="carousel-control-next" @click.prevent="nextMedia">
            <span class="material-icons">
              chevron_right
            </span>
          </a>
        </div>
        <div class="nav-container">
          <template v-if="mediasPanoramas.length > 0">
            <h5 class="small">Panoramas:</h5>
            <div class="nav-gallery--box">
              <vue-custom-scrollbar class="nav nav-gallery" :settings="scrollbarSettings" tagname="ul">
                <li class="nav-item" v-for="m in mediasPanoramas" :ref="m.code" :key="m.code">
                  <a class="nav-link" :class="{active: m === media}" href="javascript:;" @click.prevent="changeMedia(m.code)">
                    <img :src="m.thumb" height="50" :alt="m.name">
                  </a>
                </li>
              </vue-custom-scrollbar>
            </div>
          </template>
          <template v-if="mediasOthers.length > 0">
            <h5 class="small">Other media:</h5>
            <vue-custom-scrollbar class="nav nav-gallery"  :settings="scrollbarSettings" tagname="ul">
              <li class="nav-item" v-for="m in mediasOthers" :ref="m.code" :key="m.code">
                <a class="nav-link" :class="{active: m === media}" href="javascript:;" @click.prevent="changeMedia(m.code)">
                  <img :src="m.thumb" height="50" :alt="m.name">
                </a>
              </li>
            </vue-custom-scrollbar>
          </template>
        </div>
        <!--/Gallery-->
      </div>
    </div>
    <!--mod-body-->
  </div>
</template>

<script>
import SpinnerPulse from '@/components/SpinnerPulse.vue'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

import StatsAPI from '@/api/api'
import { mapGetters } from 'vuex'
import StatsSharedAPI from '@/api/sharedapi'

const CancelToken = StatsAPI.axios.CancelToken

export default {
  components: {
    SpinnerPulse,
    vueCustomScrollbar
  },
  props: {
    media: {type: Object},
    medias: {type: Array},
    uuid: {type: String, default: ""}
  },
  data () {
    return {
      scrollbarSettings: {
        suppressScrollY: true,
        suppressScrollX: false,
        wheelPropagation: true
      },
      cancelExecutor: null,
      showHeatmap: true,
      heatmapError: false,
      loadingHeatmap: false,
      heatmapOpacity: .75,
      heatmapURL: null,
      icon: 'fullscreen',
      mode: null
    }
  },
  watch: {
    media (newValue) {
      this.showHeatmap = newValue.media_type === "panorama"
      this.$refs[newValue.code][0].scrollIntoView()
      if (this.showHeatmap) {
        this.loadHeatmap()
      }
      this.onResize()
    },
    getDateFilterPayload () {
      if (this.media.media_type === "panorama") {
        this.loadHeatmap()
      }
    }
  },
  computed: {
    ...mapGetters(['getDateFilterPayload',]),
    toggleHeatmapLabel () {
      if (this.showHeatmap) {
        return "Hide heatmap"
      }
      return "Show heatmap"
    },
    modTitle () {
      if (this.media.media_type==='panorama') {
        return "Heatmap"
      }
      return "Preview"
    },
    mediaThumb () {
      if (this.media.large_url !== null) {
        return this.media.large_url
      }
      return this.media.thumb
    },
    mediasPanoramas () {
      return this.medias.filter(item => { return item.media_type === "panorama" })
    },
    mediasOthers () {
      return this.medias.filter(item => { return item.media_type !== "panorama" && item.media_type !== "photo" && item.media_type !== "album"})
    },
    validMedias () {
      return this.medias.filter(item => { return item.media_type !== "photo" && item.media_type !== "album"})      
    }
  },
  mounted () {
    this.mode = process.env.NODE_ENV
    this.$nextTick(() => {
      this.loadHeatmap()
    })
    window.addEventListener("resize", () => { this.onResize() })
  },
  methods: {
    toggleFullscreen () {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
        this.icon = 'fullscreen'
      } else {
        let element = this.$refs.gallery
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        }
        this.icon = 'fullscreen_exit'
      }
      this.onResize()
    },
    onResize () {
      if (this.showHeatmap && !this.loadingHeatmap && !this.heatmapError) {
        if (this.$refs.heatmap) {
          // this.$refs.heatmap.style.height = this.$refs.thumb.clientHeight + "px"
          // this.$refs.galleryBox.style.height = this.$refs.thumb.clientHeight + "px"
        }
      } else {
        setTimeout(() => { this.onResize() }, 500)
      }
    },
    toggleHeatmap () {
      this.showHeatmap = !this.showHeatmap
      if (this.showHeatmap) {
        this.heatmapOpacity = 1
      } else {
        this.heatmapOpacity = 0
      }
    },
    setHeatmapOpacity (op) {
      this.heatmapOpacity = op
    },
    loadHeatmap () {
      let payload = {...this.getDateFilterPayload, code: this.media.code, id: this.media.id }
      this.loadingHeatmap = true
      this.heatmapError = false
      let endpoint = StatsAPI.media_heatmap
      if (this.uuid) {
        endpoint = StatsSharedAPI.media_heatmap
        payload["uuid"] = this.uuid
      }
      endpoint(payload, new CancelToken((c) => {this.cancelExecutor = c})).then(response => {
        if (response.data.media_id !== this.media.id) {
          // discard response, as it belongs to another media
          return
        }
        if (this.mode === 'development') {
          this.heatmapURL = response.data.heatmap.replace('https://analytics.3dvista.com', '')
        } else {
          this.heatmapURL = response.data.heatmap
        }
        this.$nextTick(()=>{
          this.onResize()
        })
      }).catch((response) => {
        if (StatsAPI.axios.isCancel(response)) {
          this.heatmapURL = null
        } else {
          this.heatmapURL = null
          this.heatmapError = true
        }
      }).finally(() => {
        this.loadingHeatmap = false
      })
    },
    changeMedia (code) {
      this.cancelExecutor()
      this.$emit("changeMedia", code)
    },
    nextMedia () {
      let currIndex = this.validMedias.findIndex(item => item.code === this.media.code)
      currIndex = currIndex + 1
      if (currIndex === this.validMedias.length) {
        currIndex = 0
      }
      this.$emit("changeMedia", this.validMedias[currIndex].code)
    },
    prevMedia () {
      let currIndex = this.validMedias.findIndex(item => item.code === this.media.code)
      currIndex = currIndex - 1
      if (currIndex === -1) {
        currIndex = this.validMedias.length - 1
      }
      this.$emit("changeMedia", this.validMedias[currIndex].code)
    }
  }
};

</script>

<style lang="scss">
.btn-opacity .btn.btn-sm {
  padding: 0.15rem 0.3rem;
  font-size: 0.7em;
}
.carousel-control-prev, .carousel-control-next {
  bottom: 2em;
  cursor: pointer;
}
.mod-gallery--box {
  height: 411px;
  background: #212121;
  @media screen and (min-width: 200px) and (max-width: 1400px) {
    height: 250px;
  }
}

.errorIcon {
  font-size: 18em;
}

.btn-outline-light {
  border: 0;
}
.ps__thumb-x, .ps__thumb-y {
  background-color: #008DCA;
}

.mod-gallery:-webkit-full-screen, .mod-gallery:-moz-full-screen, .mod-gallery:-ms-fullscreen, .mod-gallery:fullscreen {

  .nav-container {
    height: 20vh;
    background-color: white;
    padding: 1em;
  }

}

</style>
