<template>
  <div :class="extraClasses" :title="value">
    <div class="number-info--description" v-if="description && upsideDown">
      <p>{{description}}</p>
    </div>
    <div class="number-info--date">
      <strong>{{bigPart}}</strong>
      <small><span v-show="showSmallPart">{{sep}}{{smallPart}}</span><span>{{unit}}</span></small>
    </div>
    <div class="number-info--description" v-if="description && !upsideDown">
      <p>{{description}}</p>
    </div>
  </div>
</template>

<script>

const pad = (num, size) => {
  let s = "000000000" + num
  return s.substr(s.length-size)
}

export default {
  props: {
    value: {
      default: 0
    },
    upsideDown: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'number',
      validator: function (value) {
        return ['percentage', 'number', 'time'].indexOf(value) !== -1
      }
    },
    description: {
      type: String,
      default: null
    },
    extraClasses: {
      type: String,
      default: "number-info number-info--large"
    }
  },
  watch: {
    value () {
      this.calculate()
    }
  },
  data () {
    return {
      bigPart: null,
      sep: null,
      smallPart: null,
      unit: null,
    }
  },
  computed: {
    showSmallPart () {
      if (this.unit === "%") {
        if (this.smallPart && parseInt(this.smallPart) === 0) {
          return false
        } else if (!this.smallPart) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    setData (bigPart, sep, smallPart, unit) {
        this.bigPart = bigPart
        this.sep = sep
        this.smallPart = smallPart
        this.unit = unit
    },
    calculate () {
      if (this.type === 'percentage') {
        let valueStr = this.value.replace("%", "")
        let hasDecimalPart = valueStr.includes('.')
        let bigPart = ""
        let smallPart = ""
        if (hasDecimalPart) {
          bigPart = valueStr.split(".")[0]
          smallPart = valueStr.split(".")[1]
        } else {
          bigPart = valueStr
        }
        this.setData(bigPart, ".", smallPart, "%")
      } else if (this.type === 'time') {
        let hours = 0
        let minutes = 0
        let seconds = this.value
        if (seconds >= 60) {
          minutes = ~~(seconds / 60)
          seconds = seconds % 60
        }
        if (minutes >= 60) {
          hours = ~~(minutes / 60)
          minutes = minutes % 60
        }
        this.setData(hours + ":" + pad(minutes, 2), ":" + pad(seconds, 2), "")

      } else {
        if (this.value < 1000) {
          this.setData(this.value, "", "", "")      
        } else if (this.value < 1000000) {
          let aprox = Math.round(this.value / 100)
          let thousands = ~~(aprox / 10)
          let rest = aprox % 10
          this.setData(thousands, ".", rest, "K")
        } else {
          let aprox = Math.round(this.value / 100000)
          let millions = ~~(aprox / 10)
          let thousands = aprox % 10
          this.setData(millions, ".", thousands, "M")
        }
      }
    }
  },
  created () {
    this.calculate()
  }
};
</script>
