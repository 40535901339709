<template>

  <div class="row row-global--header">
    <div class="col text-center">
      <!--donutChart-item-->
      <DoughnutChart :isLoading="isLoading" :value="getGlobalStats.visits" :percent="50" variant="type1" description="Total Visits" />
      <!--/donutChart-item-->
    </div>
    <div class="col text-center">
      <!--donutChart-item-->
      <DoughnutChart :isLoading="isLoading" :value="getGlobalStats.users" :percent="50" variant="type1" description="Total Users" />
      <!--/donutChart-item-->
    </div>
    <div class="col text-center">
      <!--donutChart-item-->
      <DoughnutChart :isLoading="isLoading" :value="getGlobalStats.time" :percent="50" variant="type1" type="time" description="Time average" />
      <!--/donutChart-item-->
    </div>
  </div>

</template>

<script>
  import DoughnutChart from '@/components/DoughnutChart.vue'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      DoughnutChart
    },
    props: {
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters(['getGlobalStats'])
    }
  };
</script>