<template>
  <div class="mod accordion" :class="getExtraClasses">
      <!--mod-header-->
      <div class="mod-header">
          <button
              class="btn btn-title"
              @click.prevent="toggle"
              type="button">
              <span class="material-icons">
                  expand_more
              </span>
              {{title}}
          </button>
          <div class="mod-header--tools">
            <slot name="tools"></slot>
          </div>
      </div>
      <!--/mod-header-->
      <!--mod-body-->
      <div 
          class="mod-body mod-body--space collapse show"
      >
        <transition name="collapse">
          <slot v-if="!isCollapsed"></slot>
        </transition>
      </div>
      <!--/mod-body-->
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String
    },
    extraClasses: {
      type: String,
      default: ""
    }
  },
  computed: {
    getExtraClasses() {
      let classes = this.extraClasses
      if (this.isCollapsed) {
        classes += " collapsed "
      }
      return classes
    }
  },
  data () {
    return {
      isCollapsed: false
    }
  },
  methods: {
    toggle () {
      this.isCollapsed = !this.isCollapsed
    }
  }
};
</script>

<style lang="scss" scoped>
.mod-header button span.material-icons {
  transition: all 0.2s ease;
}
.collapsed {
  .mod-header button span.material-icons {
    transform: rotate(-90deg);
  }
}

.collapse-enter-active,
.collapse-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity, transform;
  transition-timing-function: ease;
  overflow: hidden;
}

.collapse-enter,
.collapse-leave-active {
  opacity: 0;
  transform: translateY(-2em);
  overflow: hidden;
}


</style>