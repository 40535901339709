<template>
<div class="page-wrap page-error d-flex flex-row align-items-center bg-danger text-white py-5">
  <div class="container my-auto">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center">
        <span class="display-1 d-block">Ooops!</span>
        <div class="mb-4 lead">{{message}}</div>
        <router-link to="/" class="btn btn-light btn-error text-danger mx-auto"><span class="material-icons">chevron_left</span> Back to Home</router-link>
      </div>
    </div>
  </div>
</div>

</template>

<script>
  export default {
    data () {
      return {
        message: 'An unexpected error ocurred. Try again later.'
      }
    },
    mounted () {
      let msg = this.$route.query.message
      if (msg) {
        this.message = msg
      }
    }
  };
</script>

<style>
  .page-error {
    height: 80vh;
  }
  .btn-error {
    max-width: 10em;
  }
</style>
