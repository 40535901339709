<template>
  <div class="row">
      <div class="col-lg-5 mb-3">
          <GlobalAnalyticsTotals :isLoading="isLoadingStats"></GlobalAnalyticsTotals>
      </div>
      <div class="col-md mb-3">
          <!--list-group-->
          <CountriesStatsTable :isLoading="isLoadingCountries" :data="getGlobalCountriesStats"></CountriesStatsTable>
          <!--/<list-group-->
      </div>
      <div class="col-md pr-4">
        <ChartGlobalVisits :isLoading="isLoadingChart" :labels="getGlobalEvolutionStats.labels" :data="getGlobalEvolutionStats.values"></ChartGlobalVisits>
      </div>
  </div>
</template>

<script>
  import GlobalAnalyticsTotals from '@/components/GlobalAnalyticsTotals.vue'
  import CountriesStatsTable from '@/components/CountriesStatsTable.vue'
  import ChartGlobalVisits from '@/components/ChartGlobalVisits.vue'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    components: {
      GlobalAnalyticsTotals,
      CountriesStatsTable,
      ChartGlobalVisits
    },
    computed: {
      ...mapGetters(['getGlobalCountriesStats', 'getGlobalEvolutionStats', 'getDateFilterPayload', 'isLoggedIn'])
    },
    watch: {
      isLoggedIn () {
        this.dataSync()
      },
      getDateFilterPayload () {
        this.dataSync()
      }
    },
    methods: {
      ...mapActions(['updateGlobalCountriesStats', 'updateGlobalEvolutionStats', 'updateGlobalStats']),
      dataSync () {
        this.isLoadingStats = true
        this.updateGlobalStats(this.getDateFilterPayload).finally(() => {
          this.isLoadingStats = false
        })
        this.isLoadingCountries = true
        this.updateGlobalCountriesStats(this.getDateFilterPayload).finally(() => {
          this.isLoadingCountries = false
        })
        this.isLoadingChart = true
        this.updateGlobalEvolutionStats(this.getDateFilterPayload).finally(() => {
          this.isLoadingChart = false
        })
      }
    },
    created () {
      this.dataSync()
    },
    data () {
      return {
        isLoadingCountries: false,
        isLoadingChart: false,
        isLoadingStats: false
      }
    }
  };
</script>