<template>
  <router-link tag="li" :to="{name: 'tourDetail', params: {code: tour.code}}" class="list-group-item">
      <div class="list-group-item--left">
          <div class="item-thumb">
              <div class="item-thumb-img" :style="{'background-image': 'url('+ tour.thumb +')'}">
              </div>
              <span class="item-thumb--title">
                  <router-link :to="{name: 'tourDetail', params: {code: tour.code}}">{{tour.name}}</router-link>
              </span>
          </div>
      </div>
      <div class="list-group-item--right">
          <div class="list-group-item--cell" :class="{'list-group-item--active': mobileColumn === 'Percentage'}">
              <!--donutChart-item-->
              <DoughnutChart :value="tour.percentage" :percent="tour.percentage" type="percentage" variant="type2" />
              <!--/donutChart-item-->
          </div>
          <div class="list-group-item--cell" :class="{'list-group-item--active': mobileColumn === 'Total Visits'}">
            <div class="statnumber--wrapper">
              <StatNumber :value="tour.visits" type="number"></StatNumber>
            </div>
          </div>
          <div class="list-group-item--cell" :class="{'list-group-item--active': mobileColumn === 'Total Users'}">
            <div class="statnumber--wrapper">
              <StatNumber :value="tour.users" type="number"></StatNumber>
            </div>
          </div>
          <div class="list-group-item--cell" :class="{'list-group-item--active': mobileColumn === 'Time average'}">
            <div class="statnumber--wrapper">
              <StatNumber :value="tour.time" type="time"></StatNumber>
            </div>
          </div>
          <div class="list-group-item--cell" :class="{'list-group-item--active': mobileColumn === 'Country'}">
            <div class="statnumber--wrapper">
              <StatNumber :value="tour.top_country.percentage" type="percentage" :description="tour.top_country.country"></StatNumber>
            </div>
          </div>
          <div class="list-group-item--cell" :class="{'list-group-item--active': mobileColumn === 'Evolution'}">
            <ChartVisits :labels="tour.evolution_chart.labels" :height="80" :width="110" :data="tour.evolution_chart.values"></ChartVisits>
          </div>
      </div>
  </router-link>
</template>

<script>
  import DoughnutChart from '@/components/DoughnutChart.vue'
  import ChartVisits from '@/components/ChartVisits.vue'
  import StatNumber from '@/components/StatNumber.vue'

  export default {
    props: {
      tour: {
        type: Object
      },
      mobileColumn: {
        type: String,
        default: "Percentage"
      }
    },
    components: {
      ChartVisits,
      DoughnutChart,
      StatNumber
    }
  };
</script>

<style scoped>
  .list-group-item {
    min-height: 115px;
    cursor: pointer;
  }
  .item-thumb img {
    max-height: 80px
  }
  @media screen and (min-width: 1450px) {
    body .wrapper .item-thumb .item-thumb-img {
      width: 150px;
    }
  }
</style>