<template>
  <div>
  <!--mod // RESUME ////////////////////-->
  <TourResume :tour="currentTour" :detailChart="getTourChart" :prevTour="prevTour" :nextTour="nextTour"></TourResume>
  <!--mod // RESUME ////////////////////-->
  <!--mod // TABLEs ////////////////////-->
  <CollapsibleArea extraClasses="mod-gradient" title="Tour related stats">
    <div class="mod-body">
      <div class="row">
        <div class="col-md">
          <TourCountriesStatsTable :data="currentTour.countries" :isLoading="isLoading"></TourCountriesStatsTable>
        </div>
        <div class="col-md">
          <!--list-group-->
          <TourMediaStatsTable :data="getTourMedias" :isLoading="isLoadingMedias"></TourMediaStatsTable>
          <!--/<list-group-->
        </div>
        <div class="col-md">
          <!--list-group-->
          <TourHotspotStatsTable :data="getTourHotspots" :medias="getTourMedias" :isLoading="isLoadingHotspots"></TourHotspotStatsTable>
          <!--/<list-group-->
        </div>
      </div>
    </div>
  </CollapsibleArea>
  <!--/mod // TABLEs ////////////////////-->
  <!--mod // DETAIL ////////////////////-->
  <CollapsibleArea extraClasses="mod-gradient" title="Media details">
    <div class="row">
      <div class="col-md" v-if="isLoadingMedias" style="height:10em">
        <SpinnerPulse></SpinnerPulse>
      </div>
      <!--col // RESUME INFO////////////////////-->
      <div class="col-md col-media-info" v-if="getSelectedMedia && !isLoadingMedias" style="margin-bottom: 60px;">
        <!--mod-header-->
          <div class="mod-header--tools">
            <b-dropdown id="dropdownMediaMenu" variant="title">
              <template v-slot:button-content>
                <strong class="overflow-ellipsis">{{getSelectedMediaLabel}}</strong>
                <span class="material-icons">
                  expand_more
                </span>
              </template>
              <b-dropdown-item v-for="media in getMedias" :key="media.code" @click="changeMedia(media.code)">{{media.name}}</b-dropdown-item>
            </b-dropdown>
          </div>
        <!--/mod-header-->
        <!--mod-body-->
        <MediaDetails :media="getSelectedMedia" v-if="getSelectedMedia"></MediaDetails>
        <div class="alert alert-info" v-else>
          Choose media to see the stats
        </div>
        <!--/mod-body-->
      </div>
      <!--/col // RESUME INFO////////////////////-->
      <!--col // RESUME GALLERY////////////////////-->
      <MediaGallery v-if="getSelectedMedia && !isLoadingMedias" :media="getSelectedMedia" @changeMedia="changeMedia" :medias="getTourMedias"></MediaGallery>
      <!--/col // RESUME GALLERY////////////////////-->
    </div>
  </CollapsibleArea>
  <!--mod // DETAIL ////////////////////-->

  <portal to="footer">
    <!-- page navigation -->
    <div class="bar-navigation--footer sps">
      <router-link class="btn btn-sm" :to="{name: 'home'}">
        <span class="material-icons">
          chevron_left
        </span>
        <strong>Global Analytics</strong>
      </router-link>
    </div>
    <!-- /page navigation -->
  </portal>

  </div>
</template>

<script>
  import TourResume from '@/components/TourResume.vue'
  import TourCountriesStatsTable from '@/components/TourCountriesStatsTable.vue'
  import TourMediaStatsTable from '@/components/TourMediaStatsTable.vue'
  import TourHotspotStatsTable from '@/components/TourHotspotStatsTable.vue'
  import CollapsibleArea from '@/components/CollapsibleArea.vue'
  import MediaDetails from '@/components/MediaDetails.vue'
  import MediaGallery from '@/components/MediaGallery.vue'
  import { BDropdown, BDropdownItem } from 'bootstrap-vue'
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import SpinnerPulse from '@/components/SpinnerPulse.vue'

  export default {
    components: {
      TourResume,
      SpinnerPulse,
      TourCountriesStatsTable,
      TourHotspotStatsTable,
      TourMediaStatsTable,
      BDropdown,
      BDropdownItem,
      MediaDetails,
      MediaGallery,
      CollapsibleArea
    },
    data () {
      return {
        isLoading: false,
        isLoadingMedias: false,
        isLoadingHotspots: false,
        isLoadingChart: false,
        selectedMedia: null
      }
    },
    computed: {
      ...mapGetters(['getTours', 'getDateFilterPayload', 'getTourMedias', 'getTourHotspots', 'getTourChart', 'getSortConfig']),
      getSelectedMediaLabel () {
        if (this.selectedMedia === null) {
          return "Choose media"
        }
        return this.getSelectedMedia.name
      },
      getMedias () {
        return this.getTourMedias.filter(item => item.media_type !== "photo" && item.media_type !== "album")
      },
      getSelectedMedia () {
        if (this.selectedMedia) {
          return this.getTourMedias.filter(item => item.code === this.selectedMedia)[0]
        }
        return null
      },
      currentTourCode () {
        return this.$route.params.code
      },
      currentTour () {
        return this.getTours[this.currentTourIndex]
      },
      currentTourIndex () {
        return this.getTours.findIndex( (item) => item.code === this.currentTourCode )
      },
      prevTour () {
        let tourCount = this.getTours.length
        let current = this.currentTourIndex
        let prevIndex = current - 1
        if (prevIndex === -1) {
          prevIndex = tourCount - 1
        }
        return this.getTours[prevIndex]
      },
      nextTour () {
        let tourCount = this.getTours.length
        let current = this.currentTourIndex
        let nextIndex = current + 1
        if (nextIndex === tourCount) {
          nextIndex = 0
        }
        return this.getTours[nextIndex]
      }
    },
    watch: {
      getDateFilterPayload () {
        this.dataSync()
      },
      $route () {
        this.dataSync()
      }
    },
    methods: {
      ...mapActions(['updateTours', 'updateTourMedias', 'updateTourHotspots', 'updateTourDailyChart']),
      ...mapMutations(['sortTours',]),
      changeMedia (media) {
        this.selectedMedia = media
      },
      dataSync () {
        let payload = {...this.getDateFilterPayload, code: this.currentTourCode}
        this.isLoading = true
        this.updateTours(payload).finally(()=>{
          this.sortTours(this.getSortConfig)
          this.isLoading = false
        })
        this.isLoadingHotspots = true
        this.updateTourHotspots(payload).finally(()=>{
          this.isLoadingHotspots = false
        })
        this.isLoadingChart = true
        // payload = {period: 'all', code: this.currentTourCode}
        this.updateTourDailyChart(payload).finally(()=>{
          this.isLoadingChart = false
        })
        this.isLoadingMedias = true
        this.updateTourMedias(payload).finally(()=>{
          this.isLoadingMedias = false
          //if (this.selectedMedia === null) {
          if (this.getTourMedias.length) {
            this.selectedMedia = this.getTourMedias[0].code
          }
          //}
        })
      }
    },
    mounted () {  
      if (typeof this.currentTour === "undefined") {
        this.$router.push({name: 'home'})
      }
      this.dataSync()
    }
  };
</script>

<style>
  .overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  td.overflow-ellipsis {
    max-width: 10em;
  }
  #dropdownMediaMenu ul.dropdown-menu {
    overflow-y: scroll;
    max-height: 75vh;
  }
  @media screen and (min-width: 768px) {
    .col-media-info {
      min-height: 610px;
    }
  }
  .btn:focus, .btn:active {
    box-shadow: none !important;
  }

</style>
