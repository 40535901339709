<template>
  <div class="mod mod-resume">
      <div class="mod-body">
          <!--list-group-->
          <ul class="list-group list-group-flush list-group--full list-group--display">
              <li class="list-group-item list-group-item--head no-border">
                  <div class="list-group-item--left">
                  </div>
                  <div class="list-group-item--right">
                      <div class="list-group-item--cell">
                          <span>Percentage</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Total Visits</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Total Users</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Time average</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Country</span>
                      </div>
                      <div class="list-group-item--cell" @click="showModalChart">
                          <span>Evolution <i class="material-icons">zoom_in</i></span>
                      </div>
                  </div>
              </li>
              <li class="list-group-item no-border">
                  <div class="list-group-item--left">
                      <div class="item-thumb--selector">
                          <div class="item-thumb">
                              <img :src="tour.thumb">
                              <span class="item-thumb--title item-thumb--title---alltext">
                                  {{tour.name}}
                                  <!-- small>15/02/2019</small -->
                              </span>
                          </div>
                      </div>
                  </div>
                  <div class="list-group-item--right">
                      <div class="list-group-item--cell list-group-item--active">
                          <!--donutChart-item-->
                          <DoughnutChart :value="tour.percentage" :percent="tour.percentage" type="percentage" variant="type2" />
                          <!--/donutChart-item-->
                      </div>
                      <div class="list-group-item--cell">
                        <div class="statnumber--wrapper">
                          <StatNumber :value="tour.visits" type="number"></StatNumber>
                        </div>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.users" type="number"></StatNumber>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.time" type="time"></StatNumber>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.top_country.percentage" type="percentage" :description="tour.top_country.country"></StatNumber>
                      </div>
                      <div class="list-group-item--cell" @click="showModal = true">
                        <ChartVisits :labels="tour.evolution_chart.labels" :height="80" :width="110" :data="tour.evolution_chart.values"></ChartVisits>
                      </div>
                  </div>
              </li>
              <li class="list-group-item list-group-item--head no-border list-group-item---mobile">
                  <div class="list-group-item--center">
                      <div class="list-group-item--cell">
                          <span>Total Visits</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Total Users</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Time average</span>
                      </div>
                      <div class="list-group-item--cell">
                          <span>Country</span>
                      </div>
                  </div>
              </li>
              <li class="list-group-item no-border list-group-item---mobile">
                  <div class="list-group-item--center">
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.visits" type="number"></StatNumber>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.users" type="number"></StatNumber>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.time" type="time"></StatNumber>
                      </div>
                      <div class="list-group-item--cell">
                          <StatNumber :value="tour.top_country.percentage" type="percentage" :description="tour.top_country.country"></StatNumber>
                      </div>
                  </div>
              </li>
              <li class="list-group-item list-group-item---mobile" @click="showModalChart">
                  <div class="list-group-item--center">
                      <div class="list-group-item--cell">
                          <ChartVisits :labels="tour.evolution_chart.labels" :height="80" :width="110" :data="tour.evolution_chart.values"></ChartVisits>
                      </div>
                  </div>
              </li>
          </ul>
          <!--/list-group-->
      </div>
    <portal to="modals" v-if="showModal">
      <b-modal v-model="showModal" title="Daily stats" v-if="showModal" @show="isLoading=true" @shown="isLoading=false" ok-only>
        <ChartVisits :labels="detailChart.labels" :isLoading="isLoading" :height="250" :width="400" :data="detailChart.values"></ChartVisits>
      </b-modal>
    </portal>
  </div>

</template>

<script>
  import DoughnutChart from '@/components/DoughnutChart.vue'
  import ChartVisits from '@/components/ChartVisits.vue'
  import StatNumber from '@/components/StatNumber.vue'
  import { BModal } from 'bootstrap-vue'

  export default {
    components: {
      DoughnutChart,
      ChartVisits,
      StatNumber,
      BModal
    },
    data () {
      return {
        showModal: false,
        isLoading: true
      }
    },
    methods: {
      showModalChart () {
        this.showModal = true
        this.isLoading = true
      }
    },
    props: {
      tour: {
        type: Object
      },
      detailChart: {
        type: Object
      }
    }
  }; 
</script>

<style>
  .list-group-item--cell span i {
    font-size: 1em;
  }
  body .wrapper .mod .list-group.list-group--full li.list-group-item:hover {
    background-color: transparent;
  }
  .mod.mod-resume {
    padding-bottom: 0px;
    padding-top: 0px;
  }
</style>
